<template>
	<v-row>
		<BottomNavigationContainer v-if="menu.length > 1" :menu="menu" />

		<v-col cols="12" class="d-none d-md-block">
			<ToolbarContainer :menu="menu" />
			<v-toolbar elevation="1" height="25" rounded="b">
				<Breadcrumbs />
			</v-toolbar>
		</v-col>

		<v-col cols="12" lg="12">
			<router-view />
		</v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumbs from "../Widgets/Breadcrumbs";
import ToolbarContainer from "../Widgets/ToolbarContainer";
import BottomNavigationContainer from "../Widgets/BottomNavigationContainer";

export default {
	name: "ControleGaiola",
	components: { Breadcrumbs, ToolbarContainer, BottomNavigationContainer },
	data: () => ({
		menu: [],
	}),
	computed: {
		...mapState(["usuario"]),
	},
	methods: {
		montarMenu() {
			let m = [];
            if (this.permissao("controle_gaiola")) {
                m.push({
                    titulo: "Controle de equipamentos logísticos",
                    titulo_app: "Saída de Gaiolas",
                    icon: "mdi-file-pdf-box",
                    to: "/controle/gaiola",
                    tooltip:
                        "Tela para controlar saída de gaiolas",
                });
            }
            if (this.permissao("controle_gaiola")) {
              m.push({
                titulo: "Dashboard de equipamentos logísticos",
                titulo_app: "Dashboard",
                icon: "mdi-chart-box-outline",
                to: "/controle/gaiola/dashboard",
                tooltip:
                    "Tela para acompanhar movimentação de equipamentos logísticos",
              });
            }

            // if (this.permissao("controle_gaiola")) {
            //   m.push({
            //     titulo: "Controle Retorno de Gaiolas",
            //     titulo_app: "Retorno de Gaiolas",
            //     icon: "mdi-file-pdf-box",
            //     to: "/controle/gaiola/retorno",
            //     tooltip:
            //         "Tela para controlar retorno de gaiolas",
            //   });
            // }

			this.menu = m;
		},
	},
	mounted() {
		this.montarMenu();
	},
};
</script>

<style scoped>
</style>